import Column from '@/model/shared/Column';
import FILTERS_CONFIG from '@/model/modules/thirdParty/filtersConfig';

export const CONFIG = {
  headers: [
    new Column('NAME', 'product.name', true, true).setClass('max-w-lg truncate font-bold'),
    new Column('PLATFORM REPORTING ID', 'product.reportId', true, true),
    new Column('SF LINE ITEM ID', 'product.id', true, true),
    new Column('RATE', 'product.rate', true, true),
    new Column('ADVERTISER', 'advertiser.name', true, true),
    new Column('ACCOUNT', 'product.accountName', true, true).setClass('max-w-lg truncate'),
    new Column('INITIAL DATE', 'product.start', true, true),
    new Column('END DATE', 'product.end', true, true),
    new Column('VOLUME', 'product.volume', true, true).toRight(),
    new Column('PRICE', 'product.price', true, true).toRight(),
    new Column('NET PRICE', 'product.netPrice', true, true).toRight(),
    new Column('OFFICE', 'product.office', true, true).setClass('max-w-lg truncate'),
    new Column('ACCOUNT MANAGER', 'product.accountManager', true, true).setClass('max-w-lg truncate'),
    new Column('OPPORTUNITY OWNER', 'product.ownerName', true, true).setClass('max-w-lg truncate'),
    new Column('ADOPS', 'product.adopsName', true, true),
    new Column('ACTIONS', 'actions', true, false).setClass('w-4'),
  ],
  filters: [
    FILTERS_CONFIG.AD_OPS,
    FILTERS_CONFIG.ADVERTISER_ID,
    FILTERS_CONFIG.PRODUCT_ACCOUNT_MANAGER,
    FILTERS_CONFIG.PRODUCT_ACCOUNT_NAME,
    FILTERS_CONFIG.PRODUCT_LINE_ITEM_ID,
    FILTERS_CONFIG.PRODUCT_NAME,
    FILTERS_CONFIG.PRODUCT_OFFICE,
    FILTERS_CONFIG.PRODUCT_OWNER_NAME,
    FILTERS_CONFIG.PRODUCT_RATE,
    FILTERS_CONFIG.PRODUCT_REPORT_ID,
  ],
};
