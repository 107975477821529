<template>
  <asterix-section :status="sectionStatus">
    <template #header-icon>
      <product-svg class="w-8 mr-5" />
    </template>

    <template #header-title> Products </template>

    <template #content>
      <sun-filter-layout
        id="filter-box"
        :filters-added="filterFind"
        :filters-available="availableFilters"
        :has-error="anyError"
        show-reset
        @change="filtersSetFiltersFind"
        @close="filtersResetErrors"
        @remove="filtersResetErrors"
        @reset="resetFilters"
      >
        <template #quickFilters>
          <div class="min-w-max">
            <sun-search-input
              :value="filterQuick['product.name']"
              class-input="text-sm"
              @search="onQuickFiltersSearch($event, 'product.name')"
            />
          </div>
        </template>
        <!--Filters Slot :Start -->
        <template #[`filter.product.name`]="{ filter, onSelect, isDuplicate }">
          <sun-input
            type="text"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
          ></sun-input>
        </template>
        <template #[`filter.advertiser.id`]="{ filter, onSelect, value, isDuplicate }">
          <advertiser-filter
            :client-id="activeClient.id"
            :service="filter.service"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            disable-selected-options
            @change="filtersOnSelectFilter(filter, $event, onSelect)"
          />
        </template>
        <template #[`pill.text.advertiser.id`]="{ filter }">
          {{ filter | showAdvertiser }}
        </template>
        <template #[`filter.product.accountName`]="{ filter, onSelect, isDuplicate, value }">
          <asterix-async-select
            id="account-filter"
            name="account-filter"
            class="w-24"
            close-on-select
            :service="getAccountFilterOptions"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
          />
        </template>
        <template #[`filter.product.reportId`]="{ filter, onSelect, isDuplicate }">
          <sun-input
            type="text"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
          />
        </template>
        <template #[`filter.product.id`]="{ filter, onSelect, isDuplicate }">
          <sun-input
            type="text"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
          ></sun-input>
        </template>

        <template #[`filter.product.rate`]="{ filter, onSelect, isDuplicate, value }">
          <asterix-async-select
            id="rate-filter"
            name="rate-filter"
            class="w-24"
            close-on-select
            :service="getRateFilterOptions"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
          />
        </template>

        <template #[`filter.product.office`]="{ filter, onSelect, isDuplicate, value }">
          <asterix-async-select
            id="office-filter"
            name="office-filter"
            class="w-24"
            close-on-select
            :service="getOfficeFilterOptions"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
          />
        </template>

        <template #[`filter.product.accountManager`]="{ filter, onSelect, isDuplicate, value }">
          <asterix-async-select
            id="account-manager-filter"
            name="account-manager-filter"
            class="w-24"
            close-on-select
            :service="getAccountManagerFilterOptions"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
          />
        </template>

        <template #[`filter.product.ownerName`]="{ filter, onSelect, isDuplicate, value }">
          <asterix-async-select
            id="opp-owner-filter"
            name="opp-owner-filter"
            class="w-24"
            close-on-select
            :service="getOppOwnerFilterOptions"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
          />
        </template>

        <template #[`filter.product.adopsName`]="{ filter, onSelect, isDuplicate, value }">
          <asterix-async-select
            id="opp-owner-filter"
            name="opp-owner-filter"
            class="w-24"
            close-on-select
            :service="getAdopsNameFilterOptions"
            class-input="pt-0 shadow-none rounded-none multiselect"
            :value="filtersMakeFiltersForSelect(value)"
            :text-error="filtersGetFilterError(filter, isDuplicate)"
            :error="!!filtersGetFilterError(filter, isDuplicate)"
            @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
          />
        </template>
      </sun-filter-layout>

      <sun-data-table
        class="w-full"
        :content="items"
        :headers="headers"
        hoverable
        :loading="isLoading"
        striped
        @sort="onSortTable"
      >
        <template #[`col.product.name`]="{ item, columnClass }">
          <sun-data-table-cell data-testid="product-name-col" :class="columnClass">
            {{ item.name }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.reportId`]="{ item }">
          <sun-data-table-cell>
            {{ item.reportId || '-' }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.id`]="{ item }">
          <sun-data-table-cell>
            {{ item.lineItemId }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.rate`]="{ item }">
          <sun-data-table-cell>
            {{ item.rate }}
          </sun-data-table-cell>
        </template>

        <template #[`col.advertiser.name`]="{ item }">
          <sun-data-table-cell>
            {{ item.advertiser.name || '-' }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.accountName`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            {{ item.accountName }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.start`]="{ item }">
          <sun-data-table-cell>
            <date-tooltip :date="item.initialDate" class="inline-flex" />
          </sun-data-table-cell>
        </template>

        <template #[`col.product.end`]="{ item }">
          <sun-data-table-cell>
            <date-tooltip :date="item.endDate" class="inline-flex" />
          </sun-data-table-cell>
        </template>

        <template #[`col.product.volume`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            {{ item.volume }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.price`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            {{ item.price.value | currencyFilter(item.price.currency) }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.netPrice`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            {{ item.netPrice.value | currencyFilter(item.netPrice.currency) }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.office`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            {{ item.opportunity.office || '-' }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.accountManager`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            {{ item.opportunity.accountManager || '-' }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.ownerName`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            {{ item.opportunity.ownerName || '-' }}
          </sun-data-table-cell>
        </template>

        <template #[`col.product.adopsName`]="{ item }">
          <sun-data-table-cell>
            {{ item.adopsName || '-' }}
          </sun-data-table-cell>
        </template>

        <template #[`col.actions`]="{ item, columnClass }">
          <sun-data-table-cell :class="columnClass">
            <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
          </sun-data-table-cell>
        </template>

        <template #empty>
          <asterix-no-data class="bg-white" />
        </template>
      </sun-data-table>

      <sun-pagination-page
        v-show="!isLoading"
        :key="currentPage"
        :total-pages="totalPages"
        :total-items="totalItems"
        :current-page="currentPage"
        :value="itemsPerPage"
        class="my-6"
        @changePage="goToPage({ page: $event })"
        @changePerPage="changeItemsPerPage"
      />
    </template>
  </asterix-section>
</template>

<script>
import { mapActions } from 'vuex';
import { deleteModalMixin } from '@/mixins/delete/deleteModal';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import { PRODUCT_RESOURCE } from '@/services/keys';
import { ThirdPartyApi } from '@/services/api/ThirdPartyApi';
import { getSalesforceProductList } from '@/services/modules/ThirdParty/product/getSalesforceProductList';
import ProductSvg from '@/components/icons/ProductSvg';
import { CONFIG } from './config';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import AdvertiserFilter from '@/components/molecules/shared/filters/AdvertiserFilter';
import {
  getAccountFilterOptions,
  getAccountManagerFilterOptions,
  getAdOpsFilterOptions,
  getOfficeFilterOptions,
  getOwnerFilterOptions,
  getRateFilterOptions,
} from '@/services/modules/ThirdParty/filters';
import { metrics as routerProductsMetrics } from '@/router/private/modules/thirdParty/demand/product/edit';
import currencyFilter from '@/filters/currency';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

const showProduct = (router, id) => {
  router.push({ name: routerProductsMetrics.name, params: { productId: id } });
};

export default {
  name: 'ProductList',
  components: {
    AsterixSection,
    ProductSvg,
    AdvertiserFilter,
    AsterixAsyncSelect: () => import('@/components/molecules/shared/AsterixAsyncSelect'),
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    DateTooltip: () => import('@/components/atoms/DateTooltip/DateTooltip'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
  },
  filters: {
    showAdvertiser: filter => (filter ? filter.value : ' - '),
    currencyFilter: (value, currency, locale) => {
      if (currency === '~') {
        const amount = typeof value === 'string' ? parseFloat(value) : value;
        const amountFormatted =
          amount?.toLocaleString(locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }) || '';
        return `${amountFormatted} ${currency}`;
      }
      return currencyFilter(value, currency, locale);
    },
  },
  mixins: [
    activeClientMixin,
    deleteModalMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        'product.name': undefined,
      },
    }),
    indexMixin,
  ],
  data: () => ({
    headers: CONFIG.headers,
    items: [],
    currentApi: new ThirdPartyApi(),
    resource: PRODUCT_RESOURCE,
    actions: [{ name: 'Show', handler: showProduct }],
  }),
  computed: {
    defaultSort() {
      return { field: 'end', order: 'DESC' };
    },
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({
      createToast: CREATE_TOAST,
    }),
    getAccountFilterOptions(params) {
      return getAccountFilterOptions(this.activeClient.id, params);
    },
    getRateFilterOptions(params) {
      return getRateFilterOptions(this.activeClient.id, params);
    },
    getOfficeFilterOptions(params) {
      return getOfficeFilterOptions(this.activeClient.id, params);
    },
    getAccountManagerFilterOptions(params) {
      return getAccountManagerFilterOptions(this.activeClient.id, params);
    },
    getOppOwnerFilterOptions(params) {
      return getOwnerFilterOptions(this.activeClient.id, params);
    },
    getAdopsNameFilterOptions(params) {
      return getAdOpsFilterOptions(this.activeClient.id, params);
    },
    onActionClick(event, item) {
      return event.handler(this.$router, item.id);
    },
    async getTableItems() {
      try {
        this.isLoading = true;
        const { data, isCancel } = await this.getItemsFromAPI(this.getProducts);
        this.items = data;
        if (!isCancel) {
          this.isLoading = false;
        }
      } catch (e) {
        this.createToast(Toast.error(`Oops! We couldn't get the product list`, e));
      }
    },
    getProducts(params) {
      return getSalesforceProductList(params);
    },
  },
};
</script>
