var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("asterix-section", {
    attrs: { status: _vm.sectionStatus },
    scopedSlots: _vm._u([
      {
        key: "header-icon",
        fn: function () {
          return [_c("product-svg", { staticClass: "w-8 mr-5" })]
        },
        proxy: true,
      },
      {
        key: "header-title",
        fn: function () {
          return [_vm._v(" Products ")]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("sun-filter-layout", {
              attrs: {
                id: "filter-box",
                "filters-added": _vm.filterFind,
                "filters-available": _vm.availableFilters,
                "has-error": _vm.anyError,
                "show-reset": "",
              },
              on: {
                change: _vm.filtersSetFiltersFind,
                close: _vm.filtersResetErrors,
                remove: _vm.filtersResetErrors,
                reset: _vm.resetFilters,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "quickFilters",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "min-w-max" },
                          [
                            _c("sun-search-input", {
                              attrs: {
                                value: _vm.filterQuick["product.name"],
                                "class-input": "text-sm",
                              },
                              on: {
                                search: function ($event) {
                                  return _vm.onQuickFiltersSearch(
                                    $event,
                                    "product.name"
                                  )
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: `filter.product.name`,
                    fn: function ({ filter, onSelect, isDuplicate }) {
                      return [
                        _c("sun-input", {
                          attrs: {
                            type: "text",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                            enter: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.advertiser.id`,
                    fn: function ({ filter, onSelect, value, isDuplicate }) {
                      return [
                        _c("advertiser-filter", {
                          attrs: {
                            "client-id": _vm.activeClient.id,
                            service: filter.service,
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            "disable-selected-options": "",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `pill.text.advertiser.id`,
                    fn: function ({ filter }) {
                      return [
                        _vm._v(
                          " " + _vm._s(_vm._f("showAdvertiser")(filter)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: `filter.product.accountName`,
                    fn: function ({ filter, onSelect, isDuplicate, value }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "w-24",
                          attrs: {
                            id: "account-filter",
                            name: "account-filter",
                            "close-on-select": "",
                            service: _vm.getAccountFilterOptions,
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.items,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.product.reportId`,
                    fn: function ({ filter, onSelect, isDuplicate }) {
                      return [
                        _c("sun-input", {
                          attrs: {
                            type: "text",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                            enter: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.product.id`,
                    fn: function ({ filter, onSelect, isDuplicate }) {
                      return [
                        _c("sun-input", {
                          attrs: {
                            type: "text",
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                            enter: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.value,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.product.rate`,
                    fn: function ({ filter, onSelect, isDuplicate, value }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "w-24",
                          attrs: {
                            id: "rate-filter",
                            name: "rate-filter",
                            "close-on-select": "",
                            service: _vm.getRateFilterOptions,
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.items,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.product.office`,
                    fn: function ({ filter, onSelect, isDuplicate, value }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "w-24",
                          attrs: {
                            id: "office-filter",
                            name: "office-filter",
                            "close-on-select": "",
                            service: _vm.getOfficeFilterOptions,
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.items,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.product.accountManager`,
                    fn: function ({ filter, onSelect, isDuplicate, value }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "w-24",
                          attrs: {
                            id: "account-manager-filter",
                            name: "account-manager-filter",
                            "close-on-select": "",
                            service: _vm.getAccountManagerFilterOptions,
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.items,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.product.ownerName`,
                    fn: function ({ filter, onSelect, isDuplicate, value }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "w-24",
                          attrs: {
                            id: "opp-owner-filter",
                            name: "opp-owner-filter",
                            "close-on-select": "",
                            service: _vm.getOppOwnerFilterOptions,
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.items,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: `filter.product.adopsName`,
                    fn: function ({ filter, onSelect, isDuplicate, value }) {
                      return [
                        _c("asterix-async-select", {
                          staticClass: "w-24",
                          attrs: {
                            id: "opp-owner-filter",
                            name: "opp-owner-filter",
                            "close-on-select": "",
                            service: _vm.getAdopsNameFilterOptions,
                            "class-input":
                              "pt-0 shadow-none rounded-none multiselect",
                            value: _vm.filtersMakeFiltersForSelect(value),
                            "text-error": _vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                            error: !!_vm.filtersGetFilterError(
                              filter,
                              isDuplicate
                            ),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.filtersOnSelectFilter(
                                filter,
                                $event.items,
                                onSelect
                              )
                            },
                          },
                        }),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            }),
            _c("sun-data-table", {
              staticClass: "w-full",
              attrs: {
                content: _vm.items,
                headers: _vm.headers,
                hoverable: "",
                loading: _vm.isLoading,
                striped: "",
              },
              on: { sort: _vm.onSortTable },
              scopedSlots: _vm._u(
                [
                  {
                    key: `col.product.name`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          {
                            class: columnClass,
                            attrs: { "data-testid": "product-name-col" },
                          },
                          [_vm._v(" " + _vm._s(item.name) + " ")]
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.product.reportId`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _vm._v(" " + _vm._s(item.reportId || "-") + " "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.id`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _vm._v(" " + _vm._s(item.lineItemId) + " "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.rate`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _vm._v(" " + _vm._s(item.rate) + " "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.advertiser.name`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _vm._v(
                            " " + _vm._s(item.advertiser.name || "-") + " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.accountName`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          _vm._v(" " + _vm._s(item.accountName) + " "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.start`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            _c("date-tooltip", {
                              staticClass: "inline-flex",
                              attrs: { date: item.initialDate },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.product.end`,
                    fn: function ({ item }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          [
                            _c("date-tooltip", {
                              staticClass: "inline-flex",
                              attrs: { date: item.endDate },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: `col.product.volume`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          _vm._v(" " + _vm._s(item.volume) + " "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.price`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currencyFilter")(
                                  item.price.value,
                                  item.price.currency
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.netPrice`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("currencyFilter")(
                                  item.netPrice.value,
                                  item.netPrice.currency
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.office`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          _vm._v(
                            " " + _vm._s(item.opportunity.office || "-") + " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.accountManager`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          _vm._v(
                            " " +
                              _vm._s(item.opportunity.accountManager || "-") +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.ownerName`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c("sun-data-table-cell", { class: columnClass }, [
                          _vm._v(
                            " " +
                              _vm._s(item.opportunity.ownerName || "-") +
                              " "
                          ),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.product.adopsName`,
                    fn: function ({ item }) {
                      return [
                        _c("sun-data-table-cell", [
                          _vm._v(" " + _vm._s(item.adopsName || "-") + " "),
                        ]),
                      ]
                    },
                  },
                  {
                    key: `col.actions`,
                    fn: function ({ item, columnClass }) {
                      return [
                        _c(
                          "sun-data-table-cell",
                          { class: columnClass },
                          [
                            _c("table-action-menu", {
                              attrs: {
                                actions: _vm.actions,
                                item: item,
                                items: _vm.items,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onActionClick($event, item)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                  {
                    key: "empty",
                    fn: function () {
                      return [
                        _c("asterix-no-data", { staticClass: "bg-white" }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            }),
            _c("sun-pagination-page", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isLoading,
                  expression: "!isLoading",
                },
              ],
              key: _vm.currentPage,
              staticClass: "my-6",
              attrs: {
                "total-pages": _vm.totalPages,
                "total-items": _vm.totalItems,
                "current-page": _vm.currentPage,
                value: _vm.itemsPerPage,
              },
              on: {
                changePage: function ($event) {
                  return _vm.goToPage({ page: $event })
                },
                changePerPage: _vm.changeItemsPerPage,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }